<template>
  <Teleport to="body">
    <TransitionSlide
      :enter-from="TRANSITION_POSITIONS.BOTTOM"
      :enter-to="TRANSITION_POSITIONS.CENTER"
      :leave-to="TRANSITION_POSITIONS.BOTTOM"
      :with-fade="layout === 'desktop'"
    >
      <component
        :is="dynamicComponent[layout]"
        v-if="isMenuOpen"
        :cinema="cinema"
        @close="toggle"
      />
    </TransitionSlide>
  </Teleport>

  <ShowGroupsExpressBookingToggle v-if="!isMenuOpen" />
</template>

<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

defineProps<Props>()

const breakpoints = useBreakpoints(breakpointsTailwind)
const mdAndLarger = breakpoints.greaterOrEqual('md')

const dynamicComponent = {
  desktop: resolveComponent('ShowGroupsExpressBookingDesktop'),
  mobile: resolveComponent('ShowGroupsExpressBookingMobile'),
}

const layout = computed(() => (mdAndLarger.value ? 'desktop' : 'mobile'))

defineOptions({
  name: 'ShowGroupsExpressBooking',
})

onMounted(() => {
  isMenuOpen.value = false
})

const { isMenuOpen, toggle } = useExpressBooking()
</script>
