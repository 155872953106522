<template>
  <button
    type="button"
    class="border-dark-2 dark:border-light-2 flex h-14 w-full cursor-pointer items-center justify-center border-t"
    @click="handleClick"
  >
    <InlineSvg :name="SVG_ICON.CHEVRON_LEFT" class="h-5 w-5 fill-current" />
    <span v-text="label" />
  </button>
</template>

<script setup lang="ts">
interface Props {
  step: ExpressBookingSteps
}

const { currentStep, toggle } = useExpressBooking()

defineProps<Props>()

defineOptions({
  name: 'ShowGroupsExpressBookingMobileCloseBar',
})

const { t } = useI18n()

const label = computed(() => {
  if (currentStep.value === EXPRESS_BOOKING_STEP.MOVIE) {
    return t('btn.back')
  } else if (currentStep.value === EXPRESS_BOOKING_STEP.DATE) {
    return t('btn.backToMovieSelection')
  } else if (currentStep.value === EXPRESS_BOOKING_STEP.SHOW) {
    return t('btn.backToDateSelection')
  }
})

function handleClick() {
  if (currentStep.value === EXPRESS_BOOKING_STEP.MOVIE) {
    toggle()
  } else if (currentStep.value === EXPRESS_BOOKING_STEP.DATE) {
    currentStep.value = EXPRESS_BOOKING_STEP.MOVIE
  } else if (currentStep.value === EXPRESS_BOOKING_STEP.SHOW) {
    currentStep.value = EXPRESS_BOOKING_STEP.DATE
  }
}
</script>

<i18n>
de:
  btn:
    backToDateSelection: 'Zurück zur Datumsauswahl'
    backToMovieSelection: 'Zurück zur Filmauswahl'
    back: 'Zurück'
es:
  btn:
    backToDateSelection: 'Volver a la selección de fecha'
    backToMovieSelection: 'Volver a la selección de película'
    back: 'Volver'
</i18n>
