export const FILTER_OPTION = {
  GENRES: 'genres',
  CONTENT_RATINGS: 'contentRatings',
  TIMES_OF_DAY: 'timesOfDay',
  TIMES: 'times',
  DATES: 'dates',
  PERIODS: 'periods',
  SHOW_GROUPS: 'showGroups',
  AUDITORIUMS: 'auditoriums',
  CINEMAS_IDS: 'cinemaIds',
} as const
