<template>
  <slot
    :error="error"
    :pending="pending"
    :show-groups="showGroups"
    :shows="shows"
    :dates="dates"
  />
</template>

<script setup lang="ts">
import type { FetchShowGroupsForExpressBookingQueryVariables } from '#gql/default'

interface Props {
  fetchKey: string
  fetchParams: FetchShowGroupsForExpressBookingQueryVariables
}

const COMPONENT_NAME = 'ShowGroupsExpressBookingFetch'
const props = defineProps<Props>()

const { data, pending, execute, error } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.fetchKey),
  () => GqlFetchShowGroupsForExpressBooking(props.fetchParams),
  {
    immediate: false,
  }
)

await execute()

const showGroups = computed(
  () =>
    data.value?.showGroups?.filterOptions.find(
      ({ key }) => key === FILTER_OPTION.SHOW_GROUPS
    )?.values ?? []
)
const dates = computed(
  () =>
    data.value?.showGroups?.filterOptions.find(
      ({ key }) => key === FILTER_OPTION.DATES
    )?.values ?? []
)

const shows = computed(() => data.value?.showGroups?.data[0].shows?.data ?? [])

defineOptions({
  name: COMPONENT_NAME,
})
</script>
