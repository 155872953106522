const selection = reactive({
  showGroup: '',
  showGroupLabel: '',
  date: '',
  dateLabel: '',
})

const currentStep: Ref<ExpressBookingSteps> = ref(EXPRESS_BOOKING_STEP.MOVIE)

const isMenuOpen = ref(false)

export default function useExpressBooking() {
  const { $gtm } = useNuxtApp()

  function toggle(): void {
    isMenuOpen.value = !isMenuOpen.value

    if (isMenuOpen.value) {
      $gtm.push({
        event: 'toggle_express_booking',
      })
      selection.showGroup = ''
      selection.showGroupLabel = ''
      selection.date = ''
      selection.dateLabel = ''
      currentStep.value = EXPRESS_BOOKING_STEP.MOVIE
    }
  }

  return {
    selection,
    toggle,
    isMenuOpen,
    currentStep,
  }
}
