<template>
  <div
    class="bg-light dark:bg-dark text-dark dark:text-light fixed inset-0 z-[40] grid grid-rows-[auto,minmax(0,1fr),auto]"
  >
    <ShowGroupsExpressBookingMobileHeader class="h-[72px]" />

    <div class="scrollbar-themed overflow-hidden overflow-y-auto p-4">
      <FormKit v-model="selection" type="form" preserve :actions="false">
        <ShowGroupsExpressBookingMobileStepHeader
          :cinema="cinema"
          :show-group-label="selection.showGroupLabel"
          :date-label="selection.dateLabel"
        />

        <ShowGroupsExpressBookingStepShowGroup
          v-if="currentStep === EXPRESS_BOOKING_STEP.MOVIE"
          :cinema="cinema"
        />

        <ShowGroupsExpressBookingStepDate
          v-else-if="currentStep === EXPRESS_BOOKING_STEP.DATE"
          :cinema="cinema"
          :show-group="selection.showGroup"
        />

        <ShowGroupsExpressBookingStepShow
          v-else-if="currentStep === EXPRESS_BOOKING_STEP.SHOW"
          :cinema="cinema"
          :show-group="selection.showGroup"
          :date="selection.date"
        />
      </FormKit>
    </div>

    <ShowGroupsExpressBookingMobileCloseBar :step="currentStep" />
  </div>
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

defineProps<Props>()

defineOptions({
  name: 'ShowGroupsExpressBookingMobile',
})

const { toggle: toggleScrollLock } = useScrollLockWithScrollBar()
const { currentStep, selection } = useExpressBooking()

onMounted(() => {
  toggleScrollLock()
})

onUnmounted(() => {
  toggleScrollLock()
})
</script>
